import(/* webpackMode: "eager" */ "/builds/socialtool/mono/apps/frontend/src/app/[locale]/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["HandleNavigationComplete"] */ "/builds/socialtool/mono/apps/frontend/src/components/layout/navigation/handle-navigation-complete.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/socialtool/mono/apps/frontend/src/components/layout/navigation/NavigationStateChanger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/socialtool/mono/apps/frontend/src/components/layout/ProvideLanguageContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/socialtool/mono/apps/frontend/src/components/layout/UserLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/socialtool/mono/apps/frontend/src/components/WatchUserBehaviour.tsx");
;
import(/* webpackMode: "eager" */ "/builds/socialtool/mono/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"PT_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"variable\":\"--font-web\"}],\"variableName\":\"font\"}");
