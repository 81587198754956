import ButtonLink from '@/src/components/ui/atoms/button-link.tsx';
import browserLang from 'browser-lang';
import { useLanguageContext } from '@/src/components/layout/ProvideLanguageContext.tsx';
import { useLocale, useTranslations } from 'next-intl';
import NoSSR from '@mpth/react-no-ssr';
import { isSSR } from '@/src/lib/utils.ts';

const trans: Record<string, { header: string; button: string }> = {
  de: {
    header: 'Diese Seite ist auch auf deutsch verfügbar 🇩🇪',
    button: 'Wechseln',
  },
  en: {
    header: 'This page is also available in english 🇺🇸',
    button: 'Switch',
  },
};

export default function LanguageSuggestion() {
  if (isSSR()) return <></>;

  const locale = useLocale();
  const { usePathname } = useLanguageContext();
  const path = usePathname();

  const userLanguage = browserLang({
    languages: ['en', 'de'],
    fallback: 'de',
  });

  if (locale == userLanguage) {
    return <></>;
  }

  return (
    <NoSSR>
      <div className="bg-background text-foreground dark top-0 flex flex-row items-center justify-center gap-10 px-4 py-3">
        <span>{trans[userLanguage].header}</span>
        <ButtonLink
          className="text-sm"
          onPageActive="KEEP"
          size="sm"
          href={path}
          locale={userLanguage}
        >
          {trans[userLanguage].button}
        </ButtonLink>
      </div>
    </NoSSR>
  );
}
