'use client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { GtmEvent } from '@/src/helpers/analytics.ts';
import { omit } from 'lodash';

function hasPosthogException() {
  if (typeof window == 'undefined') return false;
  const re = new RegExp('allow_posthog=([^;]+)');
  const value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) === 'true' : false;
}

function hasDontTrackCookie() {
  if (typeof window == 'undefined') return false;
  const re = new RegExp('dont_track=([^;]+)');
  const value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) === 'true' : false;
}

if (typeof window !== 'undefined') {
  if (!hasDontTrackCookie() || hasPosthogException()) {
    posthog.init('phc_3VBorWhtdgeXQOMDlf2ezdfIPGOV2dvjXwUx8WtaZEK', {
      capture_pageview: false,
      api_host: `${process.env.NEXT_PUBLIC_URL}/ingest`,
      person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
    });
  }
}

/**
 * WARTEN BIS POSTHOG WIEDER EINFÜHREN
 */

export function PostHog() {
  if (hasDontTrackCookie() && !hasPosthogException()) {
    return <></>;
  }

  useEffect(() => {
    const onRouteChange = () => {
      posthog.capture('$pageview', {
        $current_url: window.location.href,
      });
    };

    window.addEventListener('pageTransitionEnd', onRouteChange);
    return () => window.removeEventListener('pageTransitionEnd', onRouteChange);
  }, []);

  useEffect(() => {
    const gtmEventListener = ({ detail }: CustomEvent<GtmEvent>) => {
      posthog.capture(detail.event, omit(detail, 'event'));
    };
    window.addEventListener('gtmEvent', gtmEventListener);
    return () => window.removeEventListener('gtmEvent', gtmEventListener);
  }, []);

  return <PostHogProvider client={posthog} />;
}
