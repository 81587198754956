'use client';

import { createPortal } from 'react-dom';
import NoSSR from '@mpth/react-no-ssr';
import React, { useEffect, useState } from 'react';
import { Button } from '@/src/components/ui/button.tsx';
import ButtonLink from '@/src/components/ui/atoms/button-link.tsx';
import { debounce } from 'lodash';
import { useTranslations } from '@/src/hooks/useTranslations';
import { isSSR } from '@/src/lib/utils.ts';
import useScroll from '@/src/hooks/useScroll.ts';

export default function CookieAlert() {
  const trans = useTranslations();

  const [shouldCookieAlert, showCookieAlert] = useState(
    !isSSR() && !localStorage.getItem('cookie'),
  );

  const acceptCookies = () => {
    showCookieAlert(false);
    localStorage.setItem('cookie', '1');
  };

  const onScroll = () => {
    if (window.scrollY > 500) {
      acceptCookies();
    }
  };
  useScroll(onScroll);

  return (
    <NoSSR>
      {shouldCookieAlert &&
        createPortal(
          <div className="text-foreground dark fixed bottom-0 z-50 w-full bg-black">
            <div className="flex h-full flex-row flex-wrap items-center justify-center gap-6 p-6 lg:flex-nowrap">
              <span className="text-justify text-sm lg:text-left">
                {trans('COOKIE_CONSENT.MESSAGE')}
              </span>
              <ButtonLink href="/datenschutz" variant="outline">
                {trans('FOOTER.DATA_PRIVACY')}
              </ButtonLink>
              <Button onClick={acceptCookies} variant="secondary">
                {trans('COMMON.CONFIRM')}
              </Button>
            </div>
          </div>,
          document.body,
        )}
    </NoSSR>
  );
}
