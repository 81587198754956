import { useEffect } from 'react';
import { debounce } from 'lodash';

export default function useScroll(
  listener: (position: number) => any,
  debounceTime: number = 50,
) {
  useEffect(() => {
    const db = debounce(() => listener(window.scrollY || 0), debounceTime, {
      maxWait: debounceTime,
    });

    window.addEventListener('scroll', db);
    return () => {
      window.removeEventListener('scroll', db);
    };
  }, [listener, debounceTime]);
}
